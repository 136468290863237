import React, { useRef, useCallback, useState } from "react"
import { FiLogIn, FiMail } from "react-icons/fi"
import { Form } from "@unform/web"
import { FormHandles } from "@unform/core"
import * as Yup from "yup"
import { Link } from "react-router-dom"

import { useToast } from "../../hooks/toast"
import getValidationErrors from "../../utils/getValidationErrors"

import logoImg from "../../assets/Logo.png"

import Input from "../../components/InputUnform"
import Button from "../../components/Button"

import { Container, Content, AnimationContainer, Background } from "./styles"
import api from "../../services/api"

interface ForgotPasswordFormData {
  email: string
}

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const formRef = useRef<FormHandles>(null)

  const { addToast } = useToast()

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        setLoading(true)

        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          email: Yup.string()
            .required("E-mail obrigatório")
            .email("Digite um e-mail válido"),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await api.post("/password/forgot", { email: data.email })

        addToast({
          type: "success",
          title: "email de recuoeração enviado",
          description:
            "enviamos um email para confirmar a recuperação de senha, cheque sua caixa de entrada",
        })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)

          return
        }

        addToast({
          type: "error",
          title: "Erro na recuperação de senha",
          description:
            "Ocorreu um erro ao tentar realizar a recuperação de senha, tente novamente",
        })
      } finally {
        setLoading(false)
      }
    },
    [addToast]
  )

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="GoBarber" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Recuperar Senha</h1>

            <Input
              name="email"
              icon={FiMail}
              type="text"
              placeholder="E-mail"
            />

            <Button loading={loading} type="submit">
              Recuperar
            </Button>
          </Form>

          <Link to="/">
            <FiLogIn />
            Voltar ao login
          </Link>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  )
}

export default ForgotPassword
